import React, { useEffect, useState } from "react";

import "./style/loader.css";

function disableLoader() {
    window.dispatchEvent(new CustomEvent("loader", {
        detail: {
            active: false
        },
        bubbles: true,
        cancelable: true,
        composed: false,
    }));
}

export {disableLoader}

export default function Loader() {

    const [invisible, setInvisible] = useState(false);
    const [active, setActive] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);

    const setLoaderEventListener = ((event: CustomEvent) => {
        if(event.detail.active == false) {
            setInvisible(true);
            setTimeout(() => {
                setActive(event.detail.active);

            }, 300);
        }else {

            setActive(event.detail.active);
        }
     }) as EventListener;

     function updatePageLoaded() {
        setPageLoaded(true);
     }

     useEffect(() => {
        window.addEventListener("loader", setLoaderEventListener);
        window.addEventListener("load", updatePageLoaded);
        if (document.readyState === 'complete') {
            updatePageLoaded();
          }
        return () => {
            window.removeEventListener("loader", setLoaderEventListener);
            window.addEventListener("load", updatePageLoaded);
        }
     })

    return <div className={"loader-wrapper" + (invisible ? " invisible" : "") + (active == false && pageLoaded == true ? " disabled" : "")}>
        <span className="loader"></span>
    </div>;
}