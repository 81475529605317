const API_URL = "https://api.vueste.de/cicalese/";

function sendNoAuthRequest(path: string, verb: string, body: any, onresp: Function) {

    sendRequest(path, verb, body, onresp, "");
}

function getImageLink(uuid: string) {
    return getApiUrl() + "image/" + uuid;
}

function sendRequest(path: string, verb: string, body: any, onresp: Function, auth: string) {
    sendRequestWithError(path, verb, body, onresp, (err: any) => {
        console.log("An error occured while api request: " + err);
    }, auth);
}

function sendRequestWithError(path: string, verb: string, body: any, onresp: Function, onerr: Function, auth: string) {

    fetch(API_URL + path, {
        method: verb,
        body: Object.keys(body).length ? JSON.stringify(body) : undefined,
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': auth,
            //TODO: Replace with real ip in apache
            "X-Real-IP": "f"
        },
    })
        .then((response) => response.json())
        .then((data) => {
            onresp(data);
        })
        .catch((err) => {
            onerr(err);
            document.body.innerHTML = "API Error: Api probably offline";
        });
}

function getApiUrl() {
    return API_URL;
}

export {sendRequest, sendNoAuthRequest, getApiUrl, sendRequestWithError, getImageLink}