
import { sendNoAuthRequest, sendRequest } from "./apiManager";

class BiographyContent {
    id: number = -1;
    title?: string;
    text?: string;
    image?: string;
}

function loadBiography(id: number, onFinish: Function) {
    sendNoAuthRequest("biography/" + id, "GET", {}, (r: any) => {
        if(r.status == 200) {
            onFinish(Object.assign(new BiographyContent(), r.data.biography));
        }else {
            console.log(r.message);
        }
    })
}

function loadBiographySections(onFinish: Function) {
    sendNoAuthRequest("biography", "GET", {}, (r: any) => {
        if(r.status == 200) {
            const sections = [];
            for(var i = 0; i < r.data.sections.length; i++) {
                const current = Object.assign(new BiographyContent(), r.data.sections[i]);
                sections.push(current);
            }
            onFinish(sections);
        }else {
            console.log(r.message)
        }
    })
}

export {loadBiography, loadBiographySections, BiographyContent}