import React, { useEffect, useState } from "react";
import SubPageHeader from "../components/subPageHeader";
import PerformanceListEl from "../components/performanceListEl";

import andrea2 from "../assets/img/andrea2.webp";

import "./style/performances.css";
import { ScrollRestoration } from "react-router-dom";
import { loadPerformances, Performance } from "../content/performanceManager";
import { getImageLink } from "../content/apiManager";
import Loader, { disableLoader } from "../components/loader";

interface PerformancesPageProps {
    loadFunction: Function,
    title: string
}

function PerformancesPage(props: PerformancesPageProps) {

    const [performances, setPerformances] = useState<Performance[]>();
    const [firstExecute, setFirstExecute] = useState(false);

    useEffect(() => {
        if(firstExecute) return;
        setFirstExecute(true);
        props.loadFunction((p: any) => {
            setPerformances(p);
            disableLoader();
        });
    })

    const [open, setOpen] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            document.body.scrollTo(0, 0);
        }, 1000);
    });

    return <div id="performances">
        <SubPageHeader title={props.title}/>
        <div className="performance-list">
            {performances?.map((e, i) => {
                return <PerformanceListEl performance={e} onClick={() => {setOpen(i)}} img={e.image ? getImageLink(e.image) : ""} open={open == i}/>
            })}
        </div>
    </div>
}

export default PerformancesPage;