import { createRef, useEffect, useState } from "react";
import { loadQuotes, Quote } from "../content/quoteManager";
import ArrowIcon from "../assets/icons/arrow";
import StraightArrowIcon from "../assets/icons/straightArrow";
import StraightShortArrowIcon from "../assets/icons/straightShortArrow";
import { time } from "console";

const QuoteSection = () => {


    const [active, setActive] = useState(0);

    const [firstExecute, setFirstExecute] = useState<boolean>(false);
    const [quotes, setQuotes] = useState<Quote[]>([]);

    const titleRef = createRef<HTMLDivElement>();

    const [width, setWidth] = useState(0);

    useEffect(() => {

        let timeoutId = window.setTimeout(nextQuote, 1000 * 10);
        return () => {
            window.clearTimeout(timeoutId);
        }

    })

    useEffect(() => {
        
        if(firstExecute) return;
        setFirstExecute(true);
        loadQuotes(setQuotes);
    })

    useEffect(() => {
        updateWidth();
        window.addEventListener("resize", updateWidth);
        return () => {
            window.removeEventListener("resize", updateWidth);
        }
    });

    function updateWidth() {
        setWidth(titleRef.current == undefined ? 0 : titleRef.current?.offsetWidth);
    }

    function nextQuote() {
        if(active == quotes.length-1) {
            setActive(0);
        }else {
            setActive(active+1);
        }
    }

    var activeQuote = quotes.length > active ? quotes[active] : new Quote();

    return <>
    <h1>Quotes</h1>
    <div className="quote">
        <div className="arrow left" onClick={() => {
            if(active == 0) {
                setActive(quotes.length -1);
            }else {
                setActive(active-1);
            }
        }}><StraightArrowIcon/></div>
        <div className="title" ref={titleRef}>
            <h2>
                {activeQuote.publisher}
            </h2>
            <p>{activeQuote.location}</p>
        </div>
        <div className="text">
            <p>
            {activeQuote.quote}
            </p>
        </div>
        <div className="arrow right" onClick={() => {
            if(active == quotes.length-1) {
                
                setActive(0);
            }else {
                setActive(active+1);
            }
        }}><StraightArrowIcon/></div>
    </div>
    <div className="indicator" style={{
        "width": "calc(" + width + "px - var(--padding)*2)"
    }}>
        {quotes.map((e, i) => {
            return <div onClick={() => {
                setActive(i);
            }} className={"line" + (i == active ? " active" : "")}>
            <div/>
        </div>;
        })}
    </div></>;
}

export default QuoteSection;