import React, { createRef, useEffect, useRef, useState } from "react";

import "./style/header.css";
import ArrowIcon from "../assets/icons/arrow";

import { scrollTo } from "./section";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {

    const navigate = useNavigate();

    const headerUlEl = createRef<HTMLUListElement>();

    const [mobileOpen, setMobileOpen] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [ active, setActive] = useState(0);
    const activeEl = createRef<HTMLLIElement>();
    const [highlightPos, setHighlightPos] = useState({
        left: -1,
        top: -1,
        width: -1
    });

    const elements = [
        <a>Home</a>,
        <a>Biography</a>,
        <a>Videos</a>,
        <a>Press</a>,
        <a>Highlights</a>,
        <a>Contact</a>,
        <>
        <a onClick={() => {
            window.open("upcoming", "_self");
        }}>On Tour<ArrowIcon/></a>
        </>
    ]

    function updateHighlightEl() {
        setHighlightPos({
            left: activeEl.current?.offsetLeft == undefined ? 0 : activeEl.current?.offsetLeft,
            top: (activeEl.current?.offsetTop == undefined ? 0 : activeEl.current?.offsetTop) + (activeEl.current?.offsetHeight == undefined ? 0 : activeEl.current?.offsetHeight),
            width: activeEl.current?.offsetWidth == undefined ? 0 : activeEl.current?.offsetWidth
        });
    }

    const setActiveHeaderEventListener = ((event: CustomEvent) => {
        setActive(event.detail.pos);
        updateHighlightEl();
     }) as EventListener;

    useEffect(() => {
        if(highlightPos.left == -1 || activeEl.current?.offsetLeft != highlightPos.left) {
            updateHighlightEl();
        }
        window.addEventListener("resize", updateHighlightEl);
        window.addEventListener("resize", checkForMobile);
        window.addEventListener("setActiveHeader", setActiveHeaderEventListener);
        checkForMobile();
        return () => {
            window.removeEventListener("resize", updateHighlightEl);
            window.removeEventListener("resize", checkForMobile);
            window.removeEventListener("setActiveHeader", setActiveHeaderEventListener);
        }
    })

    // Check for mobile

    function checkForMobile() {
        const headerWidth = 950;
        setMobile(document.body.offsetWidth < headerWidth);

    }

    return <header className={(mobile ? "mobile" : "") + (mobileOpen ? " open" : "")}>
        {
            mobile ? <div className="mobile-header">
                {elements[active]}
                <div className="burger" onClick={() => {
                    setMobileOpen(!mobileOpen);
                }}>
                    <div className="line"/>
                    <div className="line"/>
                    <div className="line"/>
                </div>
            </div> : <></>
        }
        <ul ref={headerUlEl}>
            {elements.map((e, i) => {
                var isActive = i == active;
                return <li onClick={() => {
                    scrollTo(i);
                    setTimeout(() => {
                        
                        setActive(i);
                    }, 500);
                    setMobileOpen(false);
                }} ref={isActive ? activeEl : undefined} className={isActive ? "active" : ""}>{e}</li>
            })}
        </ul>
        {highlightPos.width != -1 ? <hr style={{
            top: highlightPos.top + "px",
            left: highlightPos.left + "px",
            width: highlightPos.width + "px"
        }}/> : <></>}
    </header>
}

export default Header;