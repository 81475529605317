import React, { createRef, useEffect, useState } from "react";

import "./style/performanceListEl.css";
import { formatDateWithMonth } from "../utils/formatUtils";
import { Performance } from "../content/performanceManager";
import ArrowIcon from "../assets/icons/arrow";

interface PerformanceListElProps {
    img: any,
    open: boolean,
    onClick: Function,
    performance: Performance
}

function PerformanceListEl(props: PerformanceListElProps) {

    const subtitleExpandedRef = createRef<HTMLDivElement>();

    const [ticketsMargin, setTicketsMargin] = useState(0);

    return <div onClick={() => {props.onClick();}} className={"performance-list-el" + (props.open ? " open" : "")}>
        <div className="img" style={{
            "backgroundImage": "url(" + props.img + ")"
        }}/>
        <div className="text">
            <h3>{props.performance.getTitle()}</h3>
            <div className="subtitle">
                <p className="value">{props.performance.getSubtitle()}</p>
            </div>
            <div ref={subtitleExpandedRef} className="subtitle expanded">
                <p className={"key" + (!/\S/.test(props.performance.composer ? props.performance.composer : "") ? " solo" : "")}>{props.performance.category}</p>
                {/\S/.test(props.performance.composer ? props.performance.composer : "") ? <p className="value">{props.performance.composer}</p> : <></>}
                {props.performance.feature ? <><p className="key">with</p>
                    <p className="value">{props.performance.feature}</p></> : <></>}
                {props.performance.hall ? <><p className="key">at</p>
                    <p className="value">{props.performance.hall}</p></> : <></>}
                {props.performance.info ? <><p className="key">info</p>
                    <p className="value">{props.performance.info}</p></> : <></>}
                
            </div>
            {props.performance.link ? <div className="tickets" onClick={() => {
                    window.open(props.performance.link);
                }}>
                    <p>Get Tickets<ArrowIcon/></p>
                </div> : <></>}
            <hr/>
        </div>
    </div>;

}

export default PerformanceListEl;