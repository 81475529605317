import React, { useEffect } from "react";
import Header from "../components/header";

import "./style/imprint.css";
import SubPageHeader from "../components/subPageHeader";
import { disableLoader } from "../components/loader";
import { useFetcher } from "react-router-dom";

function ImprintPage() {

    useEffect(() => {
        disableLoader();
    });

    return <div id="imprint">
        <SubPageHeader title="Imprint"/>
        <div className="text">
            <h2>Artemon Music GmbH</h2>
            <p>Gasse 18</p>
            <p>83703 Gmund am Tegernsee</p>
            <p>E-mail: <a href="mailto:info@artemon-music.de">info@artemon-music.de</a></p>
        </div>
    </div>;
}

export default ImprintPage;