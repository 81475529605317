export default function DownloadIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="44.7" height="50.626" viewBox="0 0 44.7 50.626">
    <g id="imggal_download_button" transform="translate(-755.237 -10575)">
      <g id="Gruppe_43" data-name="Gruppe 43" transform="translate(1640.725 9874.103) rotate(135)">
        <path id="Pfad_24" data-name="Pfad 24" d="M4.789,25.676,34.275-3.81" transform="translate(1102.211 87.984)" fill="none" stroke="#fff" stroke-width="3"/>
        <path id="Pfad_23" data-name="Pfad 23" d="M1145.987,110.547V81.06H1116.5" transform="translate(-9.5 3.114)" fill="none" stroke="#fff" stroke-width="3"/>
      </g>
      <rect id="Rechteck_83" data-name="Rechteck 83" width="43" height="5.3" transform="translate(756.088 10620.326)" fill="#fff"/>
    </g>
  </svg>;
}