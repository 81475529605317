import { createRef, PropsWithChildren, RefObject, useEffect, useState } from "react";
import { getScrollPosition } from "../utils/elementPositionUtils";

const positions: (HTMLTableSectionElement)[] = [];

function scrollTo(index: number) {
    if(!positions[index]) return;
    positions[index].scrollIntoView({behavior: 'smooth', block: "start"});
}

interface SectionProps {
    headerPosition: number,
    id?: string,
    className?: string

}

const Section = (props: PropsWithChildren<SectionProps>) => {

    const offset = 30;

    const [firstCheck, setFirstCheck] = useState(false);

    const [already, setAlready] = useState(false);
    const [alreadyBack, setAlreadyBack] = useState(true);
    const ref = createRef<HTMLTableSectionElement>();

    const updateHeader = () => {
        const headerEvent = new CustomEvent("setActiveHeader", {
            detail: {
                pos: props.headerPosition
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        });
        window.dispatchEvent(headerEvent);
    }

    useEffect(() => {
        if(!firstCheck && ref.current) {
            setFirstCheck(true);
            positions[props.headerPosition] = ref.current;
        }
        window.addEventListener("scroll", onScroll);
        window.addEventListener("resize", () => {
            setFirstCheck(false);
        })
        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    })

    

    function onScroll() {
        const elPosition = getScrollPosition(ref.current);
        if(elPosition == undefined) return;
        if(window.scrollY < (elPosition!.top+offset + ref.current!.offsetHeight)) {
            if(!alreadyBack) {
                setAlreadyBack(true);
                updateHeader();
            }
        }else {
            setAlreadyBack(false);
        }
        if(elPosition!.top-offset < window.scrollY) {
            if(!already) {
                setAlready(true);
                updateHeader();
            }
        }else {
            setAlready(false);
        }
    }

    return <section ref={ref} id={props.id ? props.id : ""} className={props.className ? props.className : ""}>
        {props.children}
    </section>;
}

export default Section;
export {scrollTo}