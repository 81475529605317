import React, { createRef, useEffect, useRef, useState } from "react";

import "./style/upcomingListEl.css";
import ArrowIcon from "../assets/icons/arrow";

interface UpcomingListElProps {
    img: any,
    title: string,
    subtitle: string,
    left?: number
  }

const UpcomingListEl = (props: UpcomingListElProps) => {

    return <div style={window.innerWidth > 1300 ? {
        "width": "calc(100% - " + props.left + "px)",
        "marginLeft": props.left + "px"
    } : {}} className={"upcoming-list-el" + ((props.left != undefined && props.left != 0) ? " left" : "")}>
        <div className="img" style={{
            "backgroundImage": "url(" + props.img + ")"
        }}/>
        <div className="content">
            <h1>{props.title}</h1>
            <h1 className="subtitle">{props.subtitle}</h1>
        </div>
    </div>;
    
}

export default UpcomingListEl;