
import { sendNoAuthRequest, sendRequest } from "./apiManager";

function getStatic(key: string, onFinish: Function, onError: Function) {
    sendNoAuthRequest("static/" + key, "GET", {}, (r: any) => {
        if(r.status == 200) {
            onFinish(r.data.value);
        }else {
            onError();
        }
    });
}


export {getStatic}