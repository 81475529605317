
import { formatDateWithMonth } from "../utils/formatUtils";
import { sendNoAuthRequest, sendRequest } from "./apiManager";

class Performance {
    id: number = -1;
    category?: string;
    location?: string;
    hall?: string;
    link?: string;
    feature?: string;
    featureShort?: string;
    composer?: string;
    info?: string;
    image?: string;
    date: Date | null = null;
    /*constructor(id: number, category: string, location: string, hall: string, link: string, feature: string, featureShort: string, composer: string, info: string, image: string, date: Date) {
        this.category= category;
        this.location = location;
        this.hall = hall;
        this.link = link;
        this.feature= feature;
        this.featureShort = featureShort;
        this.composer = composer;
        this.info = info;
        this.image = image;
        this.date = date;
        this.id = id;
    }*/
   getTitle() {
    return formatDateWithMonth(this.date ? this.date : new Date()) + " - " + this.location;
   }
   getSubtitle() {
    return this.category + (this.feature ? ", with " + this.feature : "");
   }
}

var performances: Performance[] = [];

function getPerformances() {
    return performances;
}

function loadNearestPerformance(onFinish: Function) {
    sendNoAuthRequest("performance/nearest", "GET", {}, (r: any) => {
        onFinish(Object.assign(new Performance(), r.data.performance));
    });
}

function loadPerformances(onFinish: Function) {
    sendNoAuthRequest("performance", "GET", {}, (r: any) => {
        if(r.status == 200) {
            performances = [];
            for(var i = 0; i < r.data.performances.length; i++) {
                const current = Object.assign(new Performance(), r.data.performances[i]);
                performances.push(current);
            }
            onFinish(performances);
        }else {
            console.log(r.message);
        }
    });
}

function loadUpcomingPerformances(onFinish: Function) {
    sendNoAuthRequest("performance/upcoming", "GET", {}, (r: any) => {
        if(r.status == 200) {
            performances = [];
            for(var i = 0; i < r.data.performances.length; i++) {
                const current = Object.assign(new Performance(), r.data.performances[i]);
                performances.push(current);
            }
            onFinish(performances);
        }else {
            console.log(r.message);
        }
    });
}

function loadUpcomingPerformancesAmount(amount: number, onFinish: Function) {
    sendNoAuthRequest("performance/upcoming/" + amount, "GET", {}, (r: any) => {
        if(r.status == 200) {
            performances = [];
            for(var i = 0; i < r.data.performances.length; i++) {
                const current = Object.assign(new Performance(), r.data.performances[i]);
                performances.push(current);
            }
            onFinish(performances);
        }else {
            console.log(r.message);
        }
    });
}


function loadPastPerformances(onFinish: Function) {
    sendNoAuthRequest("performance/past", "GET", {}, (r: any) => {
        if(r.status == 200) {
            performances = [];
            for(var i = r.data.performances.length-1; i >= 0; i--) {
                const current = Object.assign(new Performance(), r.data.performances[i]);
                performances.push(current);
            }
            onFinish(performances);
        }else {
            console.log(r.message);
        }
    });
}

function loadOnePerformance(id: number, onFinish: Function) {
    sendNoAuthRequest("performance/" + id, "GET", {}, (r: any) => {
        onFinish(Object.assign(new Performance(), r.data.performance));
    });
}


export {Performance, getPerformances, loadPerformances, loadOnePerformance, loadNearestPerformance, loadUpcomingPerformances, loadPastPerformances, loadUpcomingPerformancesAmount}