
import { getImageLink, sendNoAuthRequest, sendRequest } from "./apiManager";

function loadImageGallery(onFinish: Function) {
    sendNoAuthRequest("gallery", "GET", {}, (r: any) => {
        if(r.status == 200) {
            const output = [];
            for(var i = 0; i < r.data.images.length; i++) {
                output.push(r.data.images[i].image);
            }
            onFinish(output);
        }else {
            console.log(r.message);
        }
    });
}

function loadRandomsFromImageGallery(amount: number, onFinish: Function) {
    sendNoAuthRequest("gallery/random/" + amount, "GET", {}, (r: any) => {
        if(r.status == 200) {
            const output = [];
            for(var i = 0; i < r.data.images.length; i++) {
                output.push(r.data.images[i].image);
            }
            onFinish(output);
        }else {
            console.log(r.message);
        }
    });
}

function downloadImage(uuid: string) {
    fetch(getImageLink(uuid))
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "andrea-" + uuid + ".png";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
}

export {loadImageGallery, downloadImage, loadRandomsFromImageGallery}