
import { sendNoAuthRequest, sendRequest } from "./apiManager";

class Quote {
    id: number = -1;
    publisher?: string;
    location?: string;
    quote?: string;

    search(text: string) {

        var split = text.split(" ");
        for(var i = 0; i < split.length; i++) {
            const current = split[i].toLowerCase();
            if(this.publisher?.toLowerCase().includes(current) || this.location?.toLowerCase().includes(current) || this.quote?.toLowerCase().includes(current)) return true;
        }
        return false;
    }
}

var quotes: Quote[] = []

function getQuotes() {
    return quotes;
}

function loadOneQuote(id: number, onFinish: Function) {
    sendNoAuthRequest("quote/" + id, "GET", {}, (r: any) => {
        if(r.status == 200) {
            onFinish(Object.assign(new Quote(), r.data.quote));
        }else {
            console.log(r.message);
        }
    })
}

function loadQuotes(onFinish: Function) {
    sendNoAuthRequest("quote", "GET", {}, (r: any) => {
        if(r.status == 200) {
            quotes = [];
            for(var i = 0; i < r.data.quotes.length; i++) {
                const current = Object.assign(new Quote(), r.data.quotes[i]);
                quotes.push(current);
            }
            onFinish(quotes);
        }else {
            console.log(r.message);
        }
    })
}


export {Quote, loadQuotes, getQuotes, loadOneQuote}