import { useNavigate } from "react-router-dom";
import ArrowIcon from "../assets/icons/arrow";
import StraightArrowIcon from "../assets/icons/straightArrow";
import "./style/button.css";

interface ButtonProps {
    name: string,
    link: string,
    dark: boolean
}

const Button = (props : ButtonProps) => {

    const navigate = useNavigate();

    return <button className={"button" + (props.dark ? " dark" : "")} onClick={() => {
        window.open(props.link, "_self");
    }}>
        <a>{props.name}</a>
        <StraightArrowIcon/>
    </button>;
}

export default Button;