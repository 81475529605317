const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  

function formatDate(d: Date) {
    var dateObj = new Date(d);
    return addZeroToNumberUnderTen(dateObj.getDate()) + "." + addZeroToNumberUnderTen(dateObj.getMonth()+1) + "." + dateObj.getFullYear();
}

function formatDateMonthYear(d: Date) {
    var dateObj = new Date(d);
    return addZeroToNumberUnderTen(dateObj.getMonth()+1) + "'" + dateObj.getFullYear().toString().substr(2, 2);
}

function formatDateWithMonth(d: Date) {
    var dateObj = new Date(d);
    const month = months[dateObj.getMonth()];
    return month + " " + (dateObj.getDate() == 1 ? "1st" : dateObj.getDate() == 2 ? "2nd" : dateObj.getDate() == 3 ? "3rd" : dateObj.getDate() + "th") + " " + dateObj.getFullYear();
}

function addZeroToNumberUnderTen(number: number) {
    if(number < 10) {
        return "0" + number;
    }
    return number;
}

export {formatDate, formatDateWithMonth, formatDateMonthYear}