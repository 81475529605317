import React from "react";

import "./style/imageBox.css";
import ArrowIcon from "../assets/icons/arrow";
import FullscreenIcon from "../assets/icons/fullscreen";
import DownloadIcon from "../assets/icons/download";
import { downloadImage } from "../content/galleryManager";

interface ImageBoxProps {
    img: any,
    onFullscreen: Function,
    onDownload: Function
}

function ImageBox(props: ImageBoxProps) {
    return <div className="img-box" style={{
        "backgroundImage": "url(" + props.img + ")"
    }}>
        <div className="fullscreen" onClick={() => {
            props.onFullscreen();
        }}>
            <FullscreenIcon/>
        </div>
        <div className="download" onClick={() => {
            props.onDownload();
        }}>
            <DownloadIcon/>
        </div>
    </div>
}

export default ImageBox;