import React, { useEffect, useState } from "react";
import SubPageHeader from "../components/subPageHeader";

import "./style/images.css";


import andrea1 from "../assets/img/andrea1.webp";
import andrea2 from "../assets/img/andrea2.webp";
import ImageBox from "../components/imageBox";
import { setScrollPosition } from "../utils/scrollUtils";
import XIcon from "../assets/icons/x";
import ArrowIcon from "../assets/icons/arrow";
import StraightArrowIcon from "../assets/icons/straightArrow";
import StraightShortArrowIcon from "../assets/icons/straightShortArrow";
import DownloadIcon from "../assets/icons/download";
import { downloadImage, loadImageGallery } from "../content/galleryManager";
import { getImageLink } from "../content/apiManager";
import { disableLoader } from "../components/loader";

function ImageGalleryPage() {

    const [firstExecute, setFirstExecute] = useState(false);
    const [images, setImages] = useState<string[]>();

    useEffect(() => {
        if(firstExecute) return;
        setFirstExecute(true);
        loadImageGallery((images: any) => {
            setImages(images);
            disableLoader();
        });        
    })

    const [fullscreen, setFullscreen] = useState("");
    const [popupClicked, setPopUpClicked] = useState(false);

    useEffect(() => {
        setScrollPosition(0, 0);
        window.scrollTo(0, 0);
        window.addEventListener("click", checkClick);
        return () => {
            window.removeEventListener("click", checkClick);
        }
    })

    function checkClick() {
        if(!popupClicked) {
            setFullscreen("");
        }
        setPopUpClicked(false);
    }

    function movePopUpImage(value: number) {
        const nextIndex = images ? images?.indexOf(fullscreen)+value : -1;
        if(images && images[nextIndex] != null) {
            setFullscreen(images ? images[nextIndex] : "");
        }
    }

    return <div id="image-gallery-page">
        <SubPageHeader title="Image Gallery"/>
        <div className="popup-overlay" style={{
            "display": fullscreen != undefined && fullscreen.length > 0 ? "block" : "none"
        }}/>
        <div className="fullscreen-popup" onClick={() => {
            setPopUpClicked(true);
        }} style={{
            "backgroundImage": "url(" + getImageLink(fullscreen) + ")",
            "display": fullscreen != undefined && fullscreen.length > 0 ? "block" : "none"
        }}>
            <div className="close" onClick={() => {
                setFullscreen("");
            }}>
                <XIcon/>
            </div>
            <div className="action-bar">
                <div className={"icon previous" + ((images != undefined && images.indexOf(fullscreen) == 0) ? " disabled" : "")} onClick={() => {
                    movePopUpImage(-1);
                }}>
                    <StraightShortArrowIcon/>
                </div>
                <div className="icon download" onClick={() => {
                    downloadImage(fullscreen);
                }}>
                    <DownloadIcon/>
                </div>
                <div className={"icon next" + ((images != undefined && images.indexOf(fullscreen) == images.length-1) ? " disabled" : "")} onClick={() => {
                    movePopUpImage(1);
                }}>
                    <StraightShortArrowIcon/>
                </div>
            </div>
        </div>

        <div className="images">
            {images?.map(e => {
                return <ImageBox img={getImageLink(e)} onDownload={() => {
                    downloadImage(e);
                }} onFullscreen={() => {
                    setPopUpClicked(true);
                    setFullscreen(e)}}/>;
            })}
        </div>
    </div>
}

export default ImageGalleryPage;