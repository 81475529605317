import "./style/footer.css";

const Footer = () => {

    return <footer>
        <div className="name">
            <h2>{new Date().getFullYear()}</h2>
            <h1>Andrea<br/>Cicalese</h1>
        </div>
        <ul>
            <li onClick={() => {
                window.open("home", "_self");
            }}>Home</li>
            <li onClick={() => {
                window.open("images", "_self");
            }}>Image Gallery</li>
            <li onClick={() => {
                window.open("archive", "_self");
            }}>Performance Archive</li>
            <li onClick={() => {
                window.open("imprint", "_self");
            }}>Imprint</li>
        </ul>
    </footer>;

}

export default Footer;