import "./style/pastElement.css";

interface PastElementProps {
    img: any,
    name: string,
    category: string,
    feature?: string,
    subtitle: string,
    right: boolean,
    color: string

}

const PastElement = (props: PastElementProps) => {
    return <div className={"past-element" + (props.right ? " right" : "")}>
        <div className="img" style={{
            "backgroundImage": "url(" + props.img + ")"
        }}/>
        <div className="text" style={{
            "backgroundColor": "var(--" + props.color + ")"
        }}>
            <h1>{props.name}</h1>
            <p><span>{props.category}</span>{props.feature ? <> with {props.feature}</> : <></>}</p>
            <p><span>{props.subtitle}</span></p>
        </div>
    </div>
}

export default PastElement;