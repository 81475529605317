
import { sendNoAuthRequest, sendRequest } from "./apiManager";

class VideoObject {
    id:number = -1;
    name?: string;
    link?: string;
    description?: string;
}

function loadVideoObject(id: number, onFinish: Function) {
    sendNoAuthRequest("videos/" + id, "GET", {}, (r: any) => {
        if(r.status == 200) {
            onFinish(Object.assign(new VideoObject(), r.data.video));
        }else {
            console.log(r.message);
        }
    });
}

function loadVideos(onFinish: Function) {
    sendNoAuthRequest("videos", "GET", {}, (r: any) => {
        if(r.status == 200) {
            var videos = [];
            for(var i = 0; i < r.data.videos.length; i++) {
                const current = Object.assign(new VideoObject(), r.data.videos[i]);
                videos.push(current);
            }
            onFinish(videos);
        }else {
            console.log(r.message);
        }
    });
}

export {VideoObject, loadVideoObject, loadVideos}