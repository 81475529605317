import "./style/imageSquare.css";

interface ImageSquareProps {
    img: any
}

const ImageSquare = (props: ImageSquareProps) => {
    return <div className="image-square" style={{
        "backgroundImage": "url(" + props.img + ")"
    }}>
        
    </div>

}

export default ImageSquare;