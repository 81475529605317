import React from "react";
import ArrowIcon from "../assets/icons/arrow";

import "./style/subPageHeader.css";
import { useNavigate } from "react-router-dom";

interface SubPageHeaderProps {
    title: string
}

function SubPageHeader(props: SubPageHeaderProps) {

    const navigate = useNavigate();

    return <div className="sub-page-header">
        <p onClick={() => {
            window.open("/home", "_self");
        }} className="back"><ArrowIcon/> Back to home</p>
        <h2>Andrea Cicalese</h2>
        <h1>{props.title}</h1>
    </div>

}

export default SubPageHeader;